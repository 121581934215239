<style lang="scss" scoped>
// .search {
//   position: fixed;
//   top: 32%;
//   z-index: 1;
//   background-color: #ffff;
//   left: 24%;
//   width: 97rem !important;
// }
</style>
<template>
  <div class="row search">
   
    <div class="col-md-12 col-sm-12 row" v-if="is_job_report">
      <div class="col-md-2 col-sm-2">
        <div class="form-group">
          <label for=""> Search</label>
          <input
            type="text"
            v-model="search.search_text"
            class="form-control"
            placeholder="Search with job title"
            name=""
            id=""
            @keypress.enter="SearchText()"
          />
        </div>
      </div>
      <div class="col-md-1">
        <button
          class="btn btn-outline-info"
          @click="SearchText()"
          style="margin-top: 25px"
        >
          <i class="fa fa-search"></i>
        </button>
      </div>
    </div>
    
    <div class="col-md-12 col-sm-12 row">
      <div class="col-md-2 col-sm-2 pr-0" v-if="is_admin_sp_company">
        <div class="form-group">
          <select
            @change="loadSpCompany()"
            name=""
            class="form-control"
            id=""
            v-model="search.sp"
          >
            <option v-if="currentUser.u.is_admin" value="all">
              All Service Provider
            </option>
            <!-- <option v-else value="all">
              All Location {{ currentUser.is_admin }}
            </option> -->
            <option
              v-for="(citem, index) in getClients"
              :key="index"
              :value="citem.id"
            >
              {{ citem?.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-2 col-sm-2 pr-0" v-if="is_company && !is_admin_sp_location ">
        <div class="form-group">
          <select v-model="search.company" name="" class="form-control" id="">
            <option value="all">All Company</option>
            <option
              v-for="(citem, index) in getMasterCompanies"
              :key="index"
              :value="citem.id"
            >
              {{ citem.company_name }}
            </option>
          </select>
        </div>
      </div>
  
      <div class="col-md-2 col-sm-2 pr-0" v-if="is_admin_sp">
        <div class="form-group">
          <select
            @change="loadSpServices()"
            name=""
            class="form-control"
            id=""
            v-model="search.sp"
          >
            <option v-if="currentUser.u.is_admin" value="all">
              All Service Provider
            </option>
            <option v-else value="all">
              All Location {{ currentUser.is_admin }}
            </option>
            <option
              v-for="(citem, index) in getClients"
              :key="index"
              :value="citem.id"
            >
              {{ citem?.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-2 col-sm-2 pr-0" v-if="is_admin_sp_location">
        <div class="form-group">
          <select
            @change="loadAdminServicesLocation()"
            name=""
            class="form-control"
            id=""
            v-model="search.sp_location"
          >
      
            <option  value="all">
              All Location 
            </option>
            <option
        
              v-for="(citem, index) in getSpClients"
              :key="index"
              :value="citem.id"
            >
              {{ citem?.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-2 col-sm-2 pr-0" v-if="is_company && is_admin_sp_location ">
        <div class="form-group">
          <select v-model="search.company" name="" class="form-control" id="">
            <option value="all">All Company</option>
            <option
              v-for="(citem, index) in getMasterCompanies"
              :key="index"
              :value="citem.id"
            >
              {{ citem.company_name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-2 col-sm-2 pr-0" v-if="is_location && !is_job_report">
        <div class="form-group">
          <select
            @change="loadServices()"
            name=""
            class="form-control"
            id=""
            v-model="search.location"
          >
            <option v-if="currentUser.u.is_admin" value="all">
              All Service Provider
            </option>
            <option v-else value="all">
              All Location {{ currentUser.is_admin }}
            </option>
            <option
              v-for="(citem, index) in getClients"
              :key="index"
              :value="citem.id"
            >
              {{ citem?.name }}
            </option>
          </select>
        </div>
      </div>

      <div class="col-md-2 col-sm-2 pr-0" v-if="is_location && is_job_report">
        <div class="form-group">
          <select
            @change="loadServices()"
            name=""
            class="form-control"
            id=""
            v-model="search.location"
          >
            <option value="all">All Location</option>
            <option
              v-for="(citem, index) in getClients"
              v-show="citem.is_mobile_fingerprinting"
              :key="index"
              :value="citem.id"
            >
              {{ citem?.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-2 col-sm-2 pr-0" v-if="is_company1">
        <div class="form-group">
          <select v-model="search.company" name="" class="form-control" id="">
            <option value="all">All Company</option>
            <option
              v-for="(citem, index) in getMasterCompanies"
              :key="index"
              :value="citem.id"
            >
              {{ citem.company_name }}
            </option>
          </select>
        </div>
      </div>

      <div
        class="col-md-2 col-sm-2 pl-2 pr-0"
        v-if="is_date_range && !is_archival"
      >
        <div class="form-group">
          <input
            type="date"
            v-model="search.date_from"
            class="form-control"
            name=""
            id=""
          />
        </div>
      </div>

      <div
        class="col-md-2 col-sm-2 pl-2 pr-0"
        v-if="is_date_range && !is_archival"
      >
        <div class="form-group">
          <input
            type="date"
            v-model="search.date_to"
            class="form-control"
            name=""
            id=""
          />
        </div>
      </div>



      <div
        class="col-md-2 col-sm-2 pl-2 pr-0"
        v-if="is_service_code"
      >
        <div class="form-group">
          <select v-model="search.service_type" name="" class="form-control" id="">
            <option value="all" >All Service Type</option>
            <option
              v-for="(citem, index) in getServiceCodes"
              :key="index"
              :value="citem.code"
            >
              {{ citem?.service_name }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="col-md-2 col-sm-2 pl-2 pr-0"
        v-if="is_service && is_admin_sp_location"
      >
        <div class="form-group">
          <select v-model="search.service" name="" class="form-control" id="">
            <option value="all">All Service</option>
            <option
              v-for="(citem, index) in getCurrentServices"
              :key="index"
              :value="citem.id"
            >
              {{ citem?.name }}
            </option>
          </select>
        </div>
      </div>
      <div
        class="col-md-2 col-sm-2 pl-2 pr-0"
        v-if="is_service && !currentUser.u.is_admin && !is_service_report"
      >
     
        <div class="form-group">
          <select v-model="search.service" name="" class="form-control" id="">
            <option value="all">All Service</option>
            <option
              v-for="(citem, index) in getCurrentServices"
              :key="index"
              :value="citem.id"
            >
              {{ citem?.name }}
            </option>
          </select>
        </div>
      </div>
      <div class="col-md-2 col-sm-2 pl-2 pr-0" v-if="is_archival">
        <div class="form-group">
          <input
            type="number"
            class="form-control"
            placeholder="No of days"
            aria-describedby="basic-addon2"
            v-model="search.no_of_days"
          />
          <span>Expires in (Days)</span>
        </div>
      </div>
      <div class="col-md-1 pl-2 pr-0" v-if="is_location_enrollment">
        <div class="form-group">
          <select v-model="search.transmit_status" class="form-control">
            <option value="all">All Status</option>
            <option value="000">Draft</option>
            <option value="100">Ready To Transmit</option>
            <option value="200">Transmitted/Completed</option>
            <!-- <option value="Refund">Refunded</option> -->
          </select>
        </div>
      </div>

      <div
        class="col-md-1 col-sm-1 pl-2 pr-0"
        v-if="is_status && !is_location_enrollment"
      >
        <div class="form-group">
          <select
            v-model="search.service_status"
            name=""
            class="form-control"
            id=""
          >
            <option value="all">All Status</option>
            <option value="P">Pending</option>
            <option value="C">Completed</option>
            <option value="U">No-Show</option>
            <option value="Cancel">Canceled</option>
            <!-- <option value="Refund">Refunded</option> -->
          </select>
        </div>
      </div>

      <div
        class="col-md-2 col-sm-2 pl-2 pr-0"
        v-if="
          is_payment_status &&
          !is_location_enrollment &&
          !is_location_appointment
        "
      >
        <div class="form-group">
          <select
            v-model="search.payment_status"
            name=""
            class="form-control"
            id=""
          >
            <option value="all">All Payment Status</option>
            <option value="P">Paid</option>
            <option value="U">Unpaid</option>
          </select>
        </div>
      </div>

      <div
        class="col-md-1 col-sm-1 adv_seach_icon"
        style="
          display: flex;
          flex-wrap: nowrap;
          align-items: center;
          top: -1rem;
        "
      >
        <button class="btn btn-outline-info btn-sm" id="btn-search" @click="advSearch()">
          <i class="fa fa-search"></i>
        </button>
        <download-excel
          v-if="reportData.length > 1"
          class="btn btn-outline-primary btn-sm ml-1"
          :data="this.reportData"
          :worksheet="this.titleworksheet"
          :fields="this.json_fields"
          :header="this.header"
          type="xls"
          :name="this.report_name"
        >
          <i class="test-white fa fa-download"></i>
        </download-excel>
      </div>
    </div>
    <!-- <div
      style="margin-left: -2rem !important;"
      class="col-md-1 col-sm-1"
      v-if="reportData.length > 0"
    >
      <download-excel
        class="btn btn-outline-primary ml-1"
        :data="this.reportData"
        :worksheet="this.titleworksheet"
        :fields="this.json_fields"
        type="xls"
        name="company-report.xls"
      >
        <i class="test-white fa fa-download"></i>
      </download-excel>
    </div> -->
    <div class="col-md-12 col-sm-12 row" v-if="is_bread_crumb">
      <nav aria-label="breadcrumb" class="ml-3">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" v-if="is_company">
            <a>{{ company.company_name }}</a>
          </li>
          <li class="breadcrumb-item" v-if="is_location">
            <a>{{ location?.name }}</a>
          </li>
          <li class="breadcrumb-item" v-if="is_date_range">
            <a
              >{{ bread_crumb_search.date_from }} -
              {{ bread_crumb_search.date_to }}</a
            >
          </li>
          <li class="breadcrumb-item" aria-current="page" v-if="is_service">
            {{ service?.name }}
          </li>
          <li class="breadcrumb-item" aria-current="page" v-if="is_status">
            {{ bread_crumb_search.service_status | filterstatus }}
          </li>
          <li
            class="breadcrumb-item"
            aria-current="page"
            v-if="is_payment_status"
          >
            {{ bread_crumb_search.payment_status | filterPayment }}
          </li>
        </ol>
      </nav>
    </div>
  </div>
</template>
<script>
import { FETCH_SERVICES,FETCH_SERVICES_CLIENTS,FETCH_MASTER_COMPANIES } from "@/core/services/store/actions.type";
import { mapGetters } from "vuex";
import JsonExcel from "vue-json-excel";
// import { EXPORT_DATA } from "@/core/services/store/actions.type";

export default {
  components: {
    "download-excel": JsonExcel,
  },
  computed: {
    getCurrentServices() {
      var services = this.getServices;
      // if (this.$route?.name != "report.location.wise.report") {
      //   var services = this.getServices;
      // } else {
      //   services = this.getServices.filter((item) => {
      //     return item.enable_ori_rate == false;
      //   });
      // }

      return services;
    },
    ...mapGetters([
      "currentUser",
      "getClients",
      "getSpClients",
      "getServices",
      "getMasterCompanies",
      "getSearchDate",
      "getServiceCodes"
    ]),
  },
  filters: {
    filterstatus: function (status) {
      if (status == "P") {
        return "Pending";
      }
      if (status == "C") {
        return "Complete";
      }
      if (status == "U") {
        return "No-show";
      }
      if (status == "Cancel") {
        return "Canceled";
      }
      if (status == "all") {
        return "All";
      }
    },
    filterPayment: function (status) {
      if (status == "P") {
        return "Paid";
      }
      if (status == "U") {
        return "Unpaid";
      }
      if (status == "all") {
        return "All";
      }
    },
  },
  data() {
    return {
      search: {
        service: "all",
        company: "all",
        date_from: "",
        date_to: "",
        service_status: "all",
        payment_status: "all",
        location: "all",
        search_text: "",
        no_of_days: "30",
        transmit_status: "all",
        sp_location:"all",
        sp:"all",
        service_type:"all"
      },

      company: {
        company_name: "All",
      },
      service: {
        name: "All",
      },
      location: {
        name: "All ",
      },
      companyreportname: "All",
      servicereportname: "All",
      locationreportname: "All",
      locationjobname: "",
      bread_crumb_search: {
        service: "all",
        company: "all",
        date_from: "",
        search_text: "",
        date_to: "",
        service_status: "all",
        payment_status: "all",
        location: "all",
        transmit_status: "all",
      
        
      },
    };
  },
  props: {
    is_company: {
      type: Boolean,
      default: false,
    },
    is_company1: {
      type: Boolean,
      default: false,
    },
    is_location: {
      type: Boolean,
      default: false,
    },
    is_job_report: {
      type: Boolean,
      default: false,
    },
    is_date_range: {
      type: Boolean,
      default: false,
    },
    is_service: {
      type: Boolean,
      default: false,
    },
    is_status: {
      type: Boolean,
      default: false,
    },
    is_payment_status: {
      type: Boolean,
      default: false,
    },
    is_bread_crumb: {
      type: Boolean,
      default: false,
    },
    is_archival: {
      type: Boolean,
      default: false,
    },
    is_service_code:{
      type:Boolean,
      default:false
    },
    reportData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    is_service_report: {
      type: Boolean,
      default: false,
    },
    report_name: {
      type: String,
      default: "",
    },
    titleworksheet: {
      type: String,
      default: "",
    },
    is_location_appointment: {
      type: Boolean,
      default: false,
    },
    is_location_enrollment: {
      type: Boolean,
      default: false,
    },
    json_fields: {
      type: Object,
      default: function () {
        return [];
      },
    },
    is_admin_sp_location:{
      type:Boolean,
      default:false,
    },
    is_admin_sp:{
      type:Boolean,
      default:false,
    },
    is_admin_sp_company:{
      type:Boolean,
      default:false,
    },
    is_default_tramist_status:{
      type:String,
      default:'all'
    }
  },
  mounted() {
    // console.log(this.getSearchDate)
    if (this.getSearchDate == null) {
      this.search = {
        service: "all",
        company: "all",
        date_from: "",
        date_to: "",
        search_text: "",
        service_status: "all",
        payment_status: "all",
        location: "all",
        transmit_status: "all",
        sp_location:"all",
        sp:"all",
        service_type:"all"
      };
      this.search.no_of_days = "30";
      this.search.date_from = this.$moment(new Date())
        .add(-7, "days")
        .format("YYYY-MM-DD");
      this.search.date_to = this.$moment(new Date()).format("YYYY-MM-DD");
    } else {
      this.search = { ...this.getSearchDate };
      this.search.company = "all";
      this.search.no_of_days = "30";
      this.search.location = "all";
      // this.search.sp_location = "all";
      // this.search.sp = "all";

      if(this.currentUser.u.is_admin == 0){
        this.search.sp = this.currentUser.c.id;
        this.search.sp_location = "all";
        this.$store.dispatch(FETCH_SERVICES_CLIENTS, this.search.sp);
      }

      this.search.transmit_status = "all";
      this.search.date_from = this.$moment(this.search.date_from).format(
        "YYYY-MM-DD"
      );
      this.search.date_to = this.$moment(this.search.date_to).format(
        "YYYY-MM-DD"
      );
    }

    if(this.is_default_tramist_status){
      this.search.transmit_status=this.is_default_tramist_status
    }
    // this.advSearch();
  },

  methods: {
    SearchText() {
      if (this.search.search_text == "") {
        this.advSearch();
      } else {
        this.setbreadcrumb();
        this.bread_crumb_search = { ...this.search };
        let header = [];
        if (this.is_job_report && this.search.search_text != "") {
          header.push("Job Report for Job Title: " + this.locationjobname);
        }
        let footer = [];
        footer.push(
          "Generated at : " +
            this.$moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
        );
        footer.push("Generated by : " + this.currentUser.u?.name);
        var search_list = {
          service: "all",
          company: "all",
          date_from: this.$moment(this.search.date_from).format("YYYY-MM-DD"),
          date_to: (this.search.date_to = this.$moment(
            this.search.date_to
          ).format("YYYY-MM-DD")),
          search_text: this.search.search_text,
          service_status: "all",
          payment_status: "all",
          transmit_status: "all",
          location: "all",
          sp: "all",
          sp_location: "all",

        };
        this.search.date_from = this.$moment(this.search.date_from).format(
          "YYYY-MM-DD"
        );
        this.search.date_to = this.$moment(this.search.date_to).format(
          "YYYY-MM-DD"
        );
        let data = {
          header: header,
          search: search_list,
          footer: footer,
        };
        // this.$store.dispatch(EXPORT_DATA, this.search);

        this.$emit("search", data);
      }
    },
    advSearch() {
      this.setbreadcrumb();
      this.search.search_text = "";
      this.bread_crumb_search = { ...this.search };

      let { date_from, date_to, service_status, payment_status } = this.search;
      let header = [];
      if (this.is_job_report && !this.currentUser.u.is_admin) {
        header.push("Job Report for Job: All");
      }
      if (this.is_location && this.is_job_report) {
        header.push("Job Report for Location: " + this.locationreportname);
      }
      if (
        this.is_location &&
        !this.is_job_report &&
        !this.is_archival &&
        !this.currentUser.u.is_admin
      ) {
        header.push("Location: " + this.locationreportname);
      } else if (this.currentUser.u.is_admin) {
        if (this.is_location_enrollment || this.is_location_appointment) {
          header.push("Report for: " + this.locationreportname);
        } else {
          header.push("Service provider : " + this.locationreportname);
        }
      }
      // if(this.is_admin_sp_location||this.is_admin_sp_company){
      //   header.push("Service provider : " + this.locationreportname);
      // }
      if (this.is_archival && this.is_location) {
        header.push("Archival Report for: " + this.locationreportname);
      }
      if (this.is_company) {
        header.push("Company Report for: " + this.companyreportname);
      }
      if (!this.is_archival) {
        header.push(" Date : " + date_from + " - " + date_to);
      }
      let header_combine = [];
      if (
        this.is_service &&
        !this.currentUser.u.is_admin &&
        !this.is_service_report
      ) {
        header_combine.push("Service: " + this.servicereportname);
      }
      if (
        this.is_status &&
        this.currentUser.u.is_admin &&
        this.is_location_appointment
      ) {
        header_combine.push(
          " Service status: " + this.filterstatus(service_status)
        );
      }
      if (this.is_status && !this.currentUser.u.is_admin) {
        header_combine.push(
          " Service status: " + this.filterstatus(service_status)
        );
      }
      if (this.currentUser.u.is_admin && this.is_location_enrollment) {
        header_combine.push(
          " Transmit status: " +
            this.filtertransmitstatus(this.search.transmit_status)
        );
      }

      if (this.is_payment_status && !this.currentUser.u.is_admin) {
        header_combine.push(
          " Payment status: " + this.filterstatus(payment_status)
        );
      }
      header.push(header_combine);
      if (this.is_location_appointment) {
        header.push("Appointments");
      }
      if (this.is_location_enrollment) {
        header.push("Enrollments");
      }
      let footer = [];
      footer.push(
        "Generated at : " +
          this.$moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      );
      footer.push("Generated by : " + this.currentUser.u?.name);

      let data = {
        header: header,
        search: this.search,
        footer: footer,
      };
      this.header = header;
      // this.$store.dispatch(EXPORT_DATA, this.search);

      this.$emit("search", data);
    },
    filterPayment(status) {
      if (status == "P") {
        return "Paid";
      }
      if (status == "U") {
        return "Unpaid";
      }
      if (status == "all") {
        return "All";
      }
    },
    filterstatus(status) {
      if (status == "P") {
        return "Pending";
      }
      if (status == "C") {
        return "Complete";
      }
      if (status == "U") {
        return "No-show";
      }
      if (status == "Cancel") {
        return "Canceled";
      }
      if (status == "all") {
        return "All";
      }
    },
    filtertransmitstatus(status) {
      if (status == "000") {
        return "Draft";
      }
      if (status == "100") {
        return "Ready To Transmit";
      }
      if (status == "200") {
        return "Transmitted/Completed";
      }

      if (status == "all") {
        return "All";
      }
    },
    loadServices() {
      this.search.service = "all";
      this.$store.dispatch(FETCH_SERVICES, this.search.location);
    },
    loadSpServices(){
      
      this.search.sp_location="all";
      this.$store.dispatch(FETCH_SERVICES_CLIENTS, this.search.sp);
      this.search.company="all";
      this.$store.dispatch(FETCH_MASTER_COMPANIES,this.search.sp)
      // if(this.is_service){
      //   //  for services inside sp
      //   this.$store.dispatch(FETCH_SERVICES, this.search.sp, this.search.sp_location);
      // }
      this.loadAdminServicesLocation()
      this.loadSpCompany()
   
    },
    loadAdminServicesLocation(){
      this.search.service="all";
     
      this.$store.dispatch(FETCH_SERVICES,{location:this.search.sp_location,sp:this.search.sp})

    },
    loadSpCompany(){
      this.search.company="all";
      this.$store.dispatch(FETCH_MASTER_COMPANIES, this.search.sp);
     
    },
    // async sleep(m) {
    //   return await setTimeout(async function() {}, m);
    // },
    setbreadcrumb() {
      let service = [...this.getServices];
      service.forEach((element) => {
        if (element.id == this.search.service) {
          this.service = element;
        }
      });
      let location = [...this.getClients];
      location.forEach((element) => {
        if (element.id == this.search.location) {
          this.location = element;
        }
      });
      let company = [...this.getMasterCompanies];
      company.forEach((element) => {
        if (element.id == this.search.company) {
          this.company = element;
        }
      });
      if (this.search.company == "all") {
        this.companyreportname = "All";
      } else {
        var company_data = company.filter(
          (item) => item.id === this.search.company
        )[0];
        this.companyreportname = company_data.company_name;
      }
      if (this.search.service == "all") {
        this.servicereportname = "All";
      } else {
        var service_data = service.filter(
          (item) => item.id === this.search.service
        )[0];

        this.servicereportname = service_data?.name;
      }
      if (this.search.location == "all") {
        this.locationreportname = "All";
      } else {
        var location_data = location.filter(
          (item) => item.id === this.search.location
        )[0];
        this.locationreportname = location_data?.name;
      }
      // header breadrump for admin reports
      if(this.search.sp =="all"){
        this.locationreportname = "All";
      }else{
         location_data = location.filter(
          (item) => item.id === this.search.sp
        )[0];
        this.locationreportname = location_data?.name;

      }
      if (this.search.search_text == "") {
        this.locationjobname = "All";
      } else {
        this.locationjobname = this.search.search_text;
      }
    },
  },
};
</script>
